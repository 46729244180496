<template>
  <div>
   <v-row no-gutters class="pa-2">
    
   </v-row>
     <v-row no-gutters class="pa-2">
           <v-col cols="6" class="pa-1" v-for="(item, index) in credits" :key="index">
             <v-card  dark class="rescard">
               <v-btn color="#333"  dark large block  @click="credit_amount=item, confirm=true"> 
                 <v-icon left color="warning">mdi-database</v-icon> {{$money(item)}}
               </v-btn>
             </v-card>
             </v-col>
             <v-col cols="12">
         <v-alert
         border="top"
         colored-border
         type="info"
         class="orange--text ma-2"
         elevation="2"
         >
         Important: Convenience Fee will be charge by using this service.
         </v-alert>
             </v-col>
     </v-row>
     <!-- <v-row no-gutters class="pa-2">
       <v-btn color="primary"  dark large block  @click="confirm=true">BUY</v-btn>
     </v-row> -->
     <va-buy-process :amount="credit_amount" :show="confirm" :user="user" @DialogEvent="hEvent"/>
     <va-payment-window :show="paymentWindow" :checkoutURL="checkoutURL" @DialogEvent="paymentWindow=false,   CHECK_BALANCE(user.id)"/>
 </div>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
       credits:[ 100, 200, 300, 400, 500, 1000, 1500,2000, 3000, 5000, 10000, 20000],
       confirm: false,
       paymentWindow: false,
       checkoutURL:"",
       credit_amount: 0
     }),
     created() {
       this.PING()
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
       // this.userDetails()
     },
      mounted(){
        this.setLoggedIn(true)
        this.setDrawer(false)
         if (this.user == null) {
           this.$router.push('/')
         }else {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
           this.CHECK_BALANCE(this.user.id)
         }
     },
     computed:{
       isOnline(){
         return this.$store.state.is_online
       },
       user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
      
     },
     methods: {
       ...mapMutations(['setDrawer','setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
       hEvent(action) {
         this.confirm = false
          if(action != 'closed') {
           console.log('resp:', action)
           if(action.resultInfo.resultStatus == "S") {
               console.log("checkoutUrl", action.checkoutUrl)
               this.checkoutURL = action.checkoutUrl
               this.paymentWindow = true
             
           } else {
             this.VA_ALERT('error', "Not Available this time!")
           }
          } 
       }
       
     },
   }
 </script>